import React from 'react';
import Contactfrom from "../contact/contactfrom";
import {Container, Row, Col} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Readybuild({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.ready_build_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'ready_build_title'))}</h2>
                        </div>
                        <div> {HtmlParser(checkData(data, 'ready_build_description'))}</div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <Contactfrom/>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Readybuild;