import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Whychoose({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.why_choose_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'why_choose_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <p>{HtmlParser(checkData(data, 'why_choose_listing'))}</p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Whychoose;