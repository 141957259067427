import React, {useEffect, useState} from 'react';
import {Button, Form} from "react-bootstrap";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {useForm} from 'react-hook-form'
import {VALIDATIONS, VALIDATIONS_TEXT} from "../../app-constants";

function Contactfrom(props) {
    const {register, handleSubmit, watch, reset, formState: {errors}} = useForm({
        mode: "onChange"
    });
    const [Success, SetSuccess] = useState(false);
    const [Looding, SetLooding] = useState(false);

    const onSubmit = async (data) => {
        SetLooding(true);
        const response = await axios.post(`${baseURL}/contact-form`, data);
        SetSuccess(true);
        setTimeout(() => {
            SetSuccess(false)
        }, 2000)
        SetLooding(false);
        reset({
            name: '',
            email: '',
            phone: '',
            company: '',
            project: '',
            description: '',
        });
    };

    return (
        <>
            <Form className="contact_from" onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="from_item">
                    <Form.Control type="text" placeholder="Full Name"
                                  name="name"
                                  {...register("name",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.NAME,
                                              message: VALIDATIONS_TEXT.NAME_MAX
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.NAME_REQUIRED
                                          },
                                      })
                                  }
                    />
                    {errors.name && <span className="error_tek">{errors.name?.message}</span>}
                </Form.Group>
                <Form.Group className="from_item">
                    <Form.Control type="email" placeholder="Email" name="email"
                                  {...register("email",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.EMAIL,
                                              message: VALIDATIONS_TEXT.EMAIL_MAX
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.EMAIL_REQUIRED
                                          },
                                          pattern: {
                                              value: /\S+@\S+\.\S+/,
                                              message: VALIDATIONS_TEXT.EMAIL_FORMAT
                                          }
                                      })
                                  }
                    />
                    {errors.email && <span className="error_tek">{errors.email?.message}</span>}
                </Form.Group>
                <Form.Group className="from_item">
                    <Form.Control type="tel" placeholder="Number" name="phone"
                                  {...register("phone",
                                      {
                                          pattern: {
                                              value: /^(\d{16}|\+?\d{11}$|\d{10,12})/,
                                              message: VALIDATIONS_TEXT.INVALID_PHONE_FORMAT,
                                          },
                                          minLength: {
                                              value: VALIDATIONS.PHONE_MIN,
                                              message: VALIDATIONS_TEXT.PHONE_MIN,
                                          },
                                          maxLength: {
                                              value: VALIDATIONS.PHONE_MAX,
                                              message: VALIDATIONS_TEXT.PHONE_MAX,
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.PHONE_REQUIRED,
                                          },
                                      })
                                  }
                    />
                    {errors.phone && <span className="error_tek">{errors.phone?.message}</span>}
                </Form.Group>
                <Form.Group className="from_item">
                    <Form.Control type="text" placeholder="Company" name="company"
                                  {...register("company",
                                      {
                                          minLength: {
                                              value: VALIDATIONS.COMPANY_MIN,
                                              message: VALIDATIONS_TEXT.COMPANY_MIN,
                                          },
                                          maxLength: {
                                              value: VALIDATIONS.COMPANY_MAX,
                                              message: VALIDATIONS_TEXT.COMPANY_MAX,
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.COMPANY_REQUIRED,
                                          },
                                      })
                                  }/>
                    {errors.company && <span className="error_tek">{errors.company?.message}</span>}
                </Form.Group>
                <Form.Group className="from_item">
                    <Form.Select name="project"
                                 {...register("project",
                                     {
                                         required: {
                                             value: true,
                                             message: VALIDATIONS_TEXT.PROJECT_REQUIRED,
                                         },
                                     })
                                 }
                    >
                        <option value="">Select Project Type</option>
                        <option value="Website Design & Dev">Website Design & Dev</option>
                        <option value="UI/UX Design">UI/UX Design</option>
                        <option value="Mobile App Design & Dev">Mobile App Design & Dev</option>
                        <option value="Blockchain Project">Blockchain Project</option>
                        <option value="AI Programming">AI Programming</option>
                        <option value="IoT Application">IoT Application</option>
                        <option value="Enterprise Application">Enterprise Application</option>
                        <option value="Microsoft Services">Microsoft Services</option>
                    </Form.Select>
                    {errors.project && <span className="error_tek">{errors.project?.message}</span>}
                </Form.Group>
                <Form.Group className="from_item">
                    <Form.Control as="textarea" rows={4} name="description"
                                  placeholder="Describe Your Project Need."
                                  {...register("description",
                                      {
                                          maxLength: {
                                              value: VALIDATIONS.DESCRIPTION_MAX,
                                              message: VALIDATIONS_TEXT.DESCRIPTION_MAX,
                                          },
                                          required: {
                                              value: true,
                                              message: VALIDATIONS_TEXT.DESCRIPTION_REQUIRED,
                                          },
                                      })
                                  }
                    />
                    {errors.description && <span className="error_tek">{errors.description?.message}</span>}
                </Form.Group>
                {!Looding ?
                    <Button variant="primary" className="btn_submit" type="submit">
                        Get In Touch
                    </Button>
                    :
                    <img src="https://www.tekrevol.com/Tek-Spinner.gif" alt="img_looder"
                         className="img-fluid looder_img"/>
                }
            </Form>

            {Success ?
                <div className="alert-success">Thank you for your message. It has been sent.</div>
                : ''
            }
        </>
    )
}

export default Contactfrom;