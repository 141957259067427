import React, {useEffect, useState} from 'react';
import Layout from "../layout/layout";
import Breadcrumbs from "../profile/breadcrumbs";
import Banner from "./banner";
import Consulting from "./consulting";
import Future from "./future";
import Harness from "./harness";
import Readybuild from "./readybuild";
import Featured from "../home/featured";
import Partner from "../home/partner";
import Awardsrecognitions from "../home/awardsrecognitions";
import Amazonservices from "./amazonservices";
import Whychoose from "./whychoose";
import axios from "axios";
import {baseURL} from "../../utils/constant";
import {checkData} from "../../helper";
import Seo from "../layout/seo";
import Loader from "../components/Loader";

function Index({slug}) {
    const [isLoaded, setIsLoaded] = useState(false);
    const [userData, setUserData] = useState([]);
    const getGiHubUserWithAxios = async () => {
        setIsLoaded(true);
        const response = await axios.get(`${baseURL}/platforms/${slug}`);
        setUserData(response.data.data);
        setIsLoaded(false);

    };
    useEffect(() => {
        getGiHubUserWithAxios();
    }, [slug]);

    return (
        <Layout>
            {isLoaded ?
                <Loader/>
                :
                <>
                    <Seo title={checkData(userData, 'meta_title')}
                         description={checkData(userData, 'meta_description')}/>
                    <Breadcrumbs link1="Technology" link2="Platforms" link3={userData.name}/>
                    <Banner data={userData}/>
                    <Featured/>
                    <Consulting data={userData}/>
                    <Future data={userData}/>
                    <Awardsrecognitions bg="bg"/>
                    <Whychoose data={userData}/>
                    <Amazonservices data={userData}/>
                    <Readybuild data={userData}/>
                    <Harness data={userData}/>
                    <Partner/>
                </>
            }
        </Layout>
    );
}

export default Index;