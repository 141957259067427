import React from 'react';
import Link from 'gatsby-link';
import {Col, Container, Row} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Future({data}) {
    return (
        <section className="tekrevol_dark_bg p-100">
            <Container>
                <Row>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div className="example" data-text={data?.consulting_services_body_txt}>
                            <h2>{HtmlParser(checkData(data, 'consulting_services_title'))}</h2>
                        </div>
                    </Col>
                    <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                        <div>{HtmlParser(checkData(data, 'consulting_services_description'))}</div>
                        <Link to={checkData(data, 'consulting_services_btn_url')}
                              className="border_btn">{checkData(data, 'consulting_services_btn_txt')}</Link>

                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Future;