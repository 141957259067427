import React from 'react';
import {Col, Container, Row, Nav, Tab, Accordion} from "react-bootstrap";
import {checkData} from "../../helper";
import HtmlParser from "react-html-parser";

function Consulting({data}) {
    return (
        <section className="tekgrip_section p-100">
            <Container className="h-100">
                <Row className="h-100">
                    <Col xs={12} md={12} lg={10} xl={10} xxl={10} className="my-auto">
                        <div className="example" data-text={data?.consulting_body_txt}>
                            <h2>{checkData(data, 'consulting_title')}</h2>
                        </div>
                        <div
                            className="main_para">{HtmlParser(checkData(data, 'consulting_description'))}</div>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="tekgrip_tabs deskstop_version">
                        <Tab.Container id="" defaultActiveKey="0">
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <Nav variant="pills" className="flex-column">
                                        {data?.consulting_tabs?.map((value, i) => (
                                            <Nav.Item key={i}>
                                                <Nav.Link eventKey={i}>{value.title}</Nav.Link>
                                            </Nav.Item>
                                        ))}
                                    </Nav>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6}>
                                    <Tab.Content>
                                        {data?.consulting_tabs?.map((value, i) => (
                                            <Tab.Pane eventKey={i} key={i}>
                                                <div>{HtmlParser(checkData(value, 'description'))}</div>
                                            </Tab.Pane>
                                        ))}
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </Col>
                    <Col xs={12} md={12} lg={8} xl={8} xxl={8} className="mobile_version">
                        <div className="tekrevol_mobile_accordion">
                            <Accordion
                                defaultActiveKey={data?.consulting_tabs?.length > 0 ? 0 : '0'}>
                                {data?.consulting_tabs?.map((item, i) => (
                                    <Accordion.Item eventKey={i}>
                                        <Accordion.Header>{item.title}</Accordion.Header>
                                        <Accordion.Body>
                                            {HtmlParser(checkData(item, 'description'))}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}

export default Consulting;